// 弹框广告
<template>
  <van-popup v-model="$store.state.adPopup.visible" closeable
    close-icon="https://hxlxxpt.hongxinglin.com/static/hxlpt/icon/icon_close.png" transition-appear
    :close-on-click-overlay="false">
    <van-image v-if="adData && imgSrc" lazy-load :src="imgSrc" @click="jumpFn" />
  </van-popup>
</template>

<script>
export default {
  name: "AdPopup",
  computed: {
    adData() {
      return this.$store.state.adPopup.adData;
    },
    imgSrc() {
      if (this.$store.state.imgUrl && this.adData.adPic) {
        return this.$store.state.imgUrl + this.adData.adPic;
      }
    },
  },
  methods: {
    jumpFn() {
      if (this.adData.jumpType === 0) {
        this.$store.dispatch("EventPoint", { eventType: 2 }).then((data) => {
          console.log("上报返回", data);
          this.$store.commit("SET_ACCESSDATA", data);
          // 关闭弹框
          this.$store.commit("SET_VISIBLE", false);
        });
        this.$router.push(this.adData.route);
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  width: 67%;
  overflow: visible;
  background-color: transparent;

  ::v-deep .van-popup__close-icon {
    top: -34px;
    right: -34px;
  }

  ::v-deep .van-popup__close-icon {
    font-size: 34px;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
  }
}
</style>
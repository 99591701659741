import Vue from "vue";
import Vuex from "vuex";
import { Toast } from "vant";

import { getDeviceInfo } from "@/assets/js/public.js";
import { getPublicImgUrl } from "@/api/public.js";
Vue.use(Vuex);
import { queryDicByType, getRegion } from "@/api/public.js";

import {
  getUserById,
  saveWechatVersion,
  tokenTransformGZH,
  checkUser,
  getUserLogin,
  getCollectionId,
} from "@/api/user";

import { Dialog } from "vant";
import router from "@/routers/router";

const modulesFiles = require.context("./store_modules", true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  state: {
    isStudy: false,
    userInfo: null,
    endData: null, //学习时长方法
    addStudyRecord: Promise.resolve(), // 学习时长记录请求结果 用于返回首页 最近学习记录的查询
    scrollTop: 0,
    isToken: false, //是否需要显示返回首页
    isBindPhone: 0, //默认未绑定手机号
    afterSalesStatus: [
      {
        title: "待补寄",
        status: 1,
      },
      {
        title: "待换货",
        status: 2,
      },
      {
        title: "已补寄",
        status: 3,
      },
      {
        title: "已换货",
        status: 4,
      },
      {
        title: "部分退款",
        status: 5,
      },
      {
        title: "已取消退款",
        status: 6,
      },
      {
        title: "待审核",
        status: 7,
      },
      {
        title: "审核拒绝",
        status: 8,
      },
      {
        title: "已退款",
        status: 9,
      },
      {
        title: "售后中",
        status: 10,
      },
    ], //售后状态
    orderStatus: [
      {
        title: "待付款",
        status: 0,
      },
      {
        title: "待发货",
        status: 1,
      },
      {
        title: "待收货",
        status: 2,
      },
      {
        title: "退款中",
        status: 3,
      },
      {
        title: "已退款",
        status: 4,
      },
      {
        title: "交易成功",
        status: 5,
      },
      {
        title: "已取消",
        status: 6,
      },
      {
        title: "交易关闭",
        status: 7,
      },
    ],
    payStatus: [
      {
        title: "待付款",
        status: 0,
      },
      {
        title: "已付款",
        status: 1,
      },
      {
        title: "退款中",
        status: 2,
      },
      {
        title: "退款成功",
        status: 3,
      },
      {
        title: "退款失败",
        status: 4,
      },
    ],
    sources: [
      {
        title: "H5",
        status: 1,
      },
      {
        title: "小程序",
        status: 2,
      },
      {
        title: "安卓",
        status: 3,
      },
      {
        title: "IOS",
        status: 4,
      },
    ],
    payment: [
      {
        title: "微信",
        status: 0,
      },
      {
        title: "支付宝",
        status: 1,
      },
      {
        title: "银行卡",
        status: 2,
      },
    ],
    sexList: ["男", "女"],
    subjectTypes: [
      {
        value: "1",
        label: "单选题",
      },
      {
        value: "2",
        label: "多选题",
      },
      {
        value: "3",
        label: "填空题",
      },
      {
        value: "4",
        label: "判断题",
      },
      {
        value: "5",
        label: "问答题",
      },
    ],
    mobileSystem: null, //用户所属系统
    checkAddress: "",
    searchText: "", //缓存关键词搜索
    imgUrl: "", //公共图片统一路径  接口获取 全局使用 默认空
    emptyImgUrl:
      "https://hxlxxpt.hongxinglin.com/static/hxlpt/images/empty2.png", // 所有空状态 呈现的图片
    closedImgUrl:
      "https://hxlxxpt.hongxinglin.com/static/hxlpt/images/icon_close_status.png", // 信息采集 关闭状态 的图标
    dics: "",
    publicImgUrl: "https://hxlxxpt.hongxinglin.com/", // 公共 静态图片 地址路径
    shareRoute: [
      "jump",
      "singleDetails",
      "specialDetails",
      "systemDetails",
      "examIndex",
      "clockIndex",
    ], //分享直接跳转地址
    version_wx: "", // 微信版本号
    isLowWX: false, // 微信版本号是否低于8 低于8微信公众号支付 否则跳转小程序支付
    refresh: true, // 刷新token后 刷新路由
    showAuthPop: false, // 隐藏显示授权登录提示框
    authLink: undefined, // 弹框授权登录回跳的页面
    snsapi_userinfo: "snsapi_userinfo",
    snsapi_base: "snsapi_base",
    filterForm: {}, // 分类筛选 已选项
    isLoading: false, // 直播间加载中
    userSource: 0, // source 0: h5 1: 小程序 2: app
    moreUnionIdUsers: [], // 多unionId 用户数据
  },

  mutations: {
    increment(state, data) {
      state.scrollTop = data;
    },
    setIsToken(state, data) {
      //修改
      state.isToken = data;
    },
    /**用户默认选中的地址 */
    setCheckAddress(state, data) {
      state.checkAddress = data;
    },
    searchData(state, data) {
      //关键词搜索处理
      state.searchText = data;
    },
    setPhoneStatus(state, data) {
      state.isBindPhone = data;
    },
    SETTING_PUBLIC_IMGURL(state, data) {
      state.imgUrl = data;
      localStorage.setItem("imgUrl", data);
    },
    SETTING_PUBLIC_TIME_DIFF(state, data) {
      // 同步服务器时间
      state.timeDiff = data;
      sessionStorage.setItem("timeDiff", data);
    },
    SETTING_DICS(state, data) {
      state.dics = data;
    },
    addStudyRecord(state, data) {
      state.addStudyRecord = data;
    },
    SETTING_AREALIST(state, data) {
      state.areaList = data;
    },
    SETTING_USERINFO(state, data) {
      state.userInfo = data;
      localStorage.setItem($GLOBAL.sessionKey, JSON.stringify(data));
    },
    SETTING_VERSION(state, data) {
      state.version_wx = data || "";
      localStorage.setItem("version_wx", data);
    },
    SET_IS_LOW_WX(state, data) {
      state.isLowWX = typeof data == "boolean" ? data : data.split(".")[0] < 8;
    },
    SET_TOKEN(state, data) {
      // 写入token
      state.userToken = data;
      localStorage.setItem("userToken", data);
      if (data) {
        // 有用户登录信息 代表有过snsapi_userinfo授权 记录
        state.authScope = state.snsapi_userinfo;
      }
    },
    SET_HUID(state, data) {
      // 写入用户id
      state.huid = data;
      localStorage.setItem("huid", data);
      // 登录后配置阿里云arms上报用户id
      // setTimeout(() => {
      //   if (typeof __bl.setConfig == "function") {
      //     __bl.setConfig({
      //       uid: state.huid,
      //     });
      //   }
      // }, 10);
    },
    REFRESH(state, data) {
      // 刷新token 后 刷新路由 页面
      state.refresh = data;
    },
    SET_BEFORE_AUTH(state, fn) {
      // 点击授权登录优先执行操作 fn（function）
      state.beforeAuth = () => {
        typeof fn == "function" && fn();
        state.beforeAuth = null;
      };
    },
    // 记录snsapi_base授权返回
    SET_AUTH_SCOPE(state, data) {
      state.authScope = data;
    },
    WX_AUTH(state, data) {
      /**
       * 微信授权 针对需要微信授权登录页面
       * 1、进入页面默认进行一次snsapi_base授权，如果有用户信息则登录完成
       * 2、如果无用户信息,页面是游客模式（路由配guest）可浏览的，不做操作，当用户点击到非游客模式的操作时，显示登录提示框 SHOW_AUTH_POP
       * 3、页面游客模式不可浏览的，跳转登录界面login，由用户主动发起微信snsapi_userinfo授权登录data: { authScope: 'snsapi_userinfo', href: --- }
       */
      const url = data?.href || window.location.href;
      const link = encodeURIComponent(url.replace("/#", "?t=" + Date.now()));
      const authScope = data?.authScope || state.snsapi_base;
      window.location.href =
        $GLOBAL.serverSrc +
        "/user/user/auth/userAuthLogin?link=" +
        link +
        "&authScope=" +
        authScope +
        "&t=" +
        Date.now() +
        "&mobileSystem=" +
        state.mobileSystem;
    },
    // 显示授权微信登录提示框
    SHOW_AUTH_POP(state, data) {
      state.showAuthPop = true;
      state.authLink = data.href;
    },
    // 关闭隐藏授权微信登录提示框
    HIDE_AUTH_POP(state, data) {
      state.showAuthPop = false;
      state.authLink = undefined;
    },
    // 分类筛选记录 用户学习页面点入其它页面 返回时使用, tab切换时清空
    SET_DEFAULT_FILTER(state, data = {}) {
      state.filterForm = data;
    },
    SET_LIVE_LOADING(state, data) {
      state.isLoading = data;
    },
    SET_USER_SOURCE(state, data) {
      state.userSource = data;
    },
    // 多 unionId 用户列表数据
    SET_MORE_UNIONID_USERS(state, data) {
      state.moreUnionIdUsers = data;
    },
  },
  actions: {
    // checkLogin({ state }, data) {
    //   const isCheckLogin = sessionStorage.getItem('isCheckLogin')
    //   if (!state.userToken) return Promise.reject()
    //   if (isCheckLogin == 1) return Promise.resolve()
    //   return new Promise((resolve, reject) => {
    //     getUserLogin().then((res) => {
    //       console.log(res);
    //       if (res.data.code != -1001) {
    //         // 已登录
    //         sessionStorage.setItem('isCheckLogin', 1)
    //         resolve()
    //         return
    //       }
    //       // 未登录
    //       reject()
    //     });
    //   })
    // },
    // 校验用户状态，多unionId 异常处理
    // CheckIsDupUnionId(state) {
    //   if (!state.state.huid) {
    //     router.replace({
    //       path: "/login",
    //       query: {
    //         link: location.href,
    //       },
    //     });
    //     return new Promise(() => {});
    //   }
    //   return new Promise((resolve, reject) => {
    //     state.dispatch("GetUser").then((user) => {
    //       if (user.dupUnionId) {
    //         getCollectionId({
    //           dupUnionId: user.dupUnionId,
    //           huid: user.huid,
    //         }).then((res) => {
    //           if (res?.data?.code == 200) {
    //             if (res.data.data > 0) {
    //               Dialog.confirm({
    //                 title: "提示",
    //                 confirmButtonText: "提交问卷",
    //                 message:
    //                   "系统检测到您的账号存在异常情况，请填写问卷帮助我们排查您的问题",
    //               })
    //                 .then(() => {
    //                   router.replace({
    //                     path: "/infoGather",
    //                     query: {
    //                       collectionInfoId: res.data.data,
    //                       dupUnionId: 1,
    //                       link: location.href,
    //                     },
    //                   });
    //                 })
    //                 .catch(() => {});
    //               resolve(true);
    //             }
    //             resolve(false);
    //           }
    //           reject();
    //         });
    //         return;
    //       }
    //       resolve(false);
    //     });
    //   });
    // },
    setPhone(state, data) {
      state.commit("setPhoneStatus", data);
    },
    SetPublicImgUrl({ state, commit }) {
      return new Promise((resolve, reject) => {
        getPublicImgUrl().then((res) => {
          if (res?.data?.code == 200) {
            console.log(res, "yjjj");
            commit("SETTING_PUBLIC_IMGURL", res.data.data.filePath);
            commit(
              "SETTING_PUBLIC_TIME_DIFF",
              new Date().getTime() - res.data.data.nowDate
            );
            return;
          }
          Toast.fail(res?.data?.message || res?.data?.code);
        });
      });
    },
    SetRegion({ state, commit }) {
      // 省市区列表
      if (state.areaList) {
        return Promise.resolve(state.areaList);
      } else {
        return new Promise((resolve, reject) => {
          getRegion().then((res) => {
            if (res?.data?.code == 200) {
              commit("SETTING_AREALIST", res.data.data);
              resolve(res.data.data);
              return;
            }
            // reject(res);
            Toast.fail(res?.data?.message || res?.data?.code);
          });
        });
      }
    },
    SaveVersion({ state, commit }) {
      // 写入版本号 微信 手机型号 手机系统
      try {
        const { wechat, device, system } = getDeviceInfo();
        const localStoreWechat = localStorage.getItem("version_wx");
        if (localStoreWechat == wechat) {
          commit("SETTING_VERSION", wechat);
          commit("SET_IS_LOW_WX", wechat);
        } else {
          let sys = undefined;
          if (system?.length > 14) {
            sys = system.slice(0, 14);
          } else {
            sys = system;
          }
          const params = {
            // phoneVersion: "iPhone,iPhone OS 11_0"
            // wechatVersion: "8.0.5"
            phoneVersion: `${device},${sys}`,
            wechatVersion: wechat,
          };

          saveWechatVersion(params).then((res) => {
            if (res?.data?.code == 200) {
              commit("SETTING_VERSION", wechat);
              commit("SET_IS_LOW_WX", wechat);
              return;
            }
            Toast.fail(res?.data?.message || res?.data?.code);
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    GetUser({ state, commit }) {
      // 用户信息
      if (state.userInfo && state.userInfo.huid) {
        return Promise.resolve(state.userInfo);
      } else {
        return new Promise((resolve, reject) => {
          getUserById().then((res) => {
            if (res?.data?.code == 200) {
              var user = res?.data?.data || {};
              // user3.2用户绑定手机号从bindPhoneInfo.phone 获取
              user.phone = user.bindPhoneInfo?.phone || "";
              // __bl.setConfig({
              //   // uid: user.huid,
              //   setUsername: function() {
              //     return user.nickname;
              //   },
              // });
              sessionStorage.setItem($GLOBAL.sessionKey, JSON.stringify(user));
              commit("SETTING_USERINFO", user);
              resolve(user);
              return;
            }
            // reject(res);
            Toast.fail(res?.data?.message || res?.data?.code);
          });
        });
      }
    },
    ChangeAppToken({ state, commit }, params) {
      // 转换token source => target 0: h5 1: 小程序 2: app
      return new Promise((resolve, reject) => {
        tokenTransformGZH({
          ...params,
          huId: state.huid,
        }).then((res) => {
          if (res?.data?.code == 200) {
            commit("SET_TOKEN", res.data.data);
            commit("SET_USER_SOURCE", params.source);
            resolve();
            return;
          }
          Toast.fail(res?.data?.message || res?.data?.code);
        });
      });
    },
    // 检测是否多 unionId 用户
    CheckUser({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        checkUser({
          ...params,
        }).then((res) => {
          if (res?.data?.code == 200) {
            commit("SET_MORE_UNIONID_USERS", res.data.data || []);
            resolve(res.data.data || []);
            return;
          }
          Toast.fail(res?.data?.message || res?.data?.code);
        });
      });
    },
    // 获取公共字典表
    queryDicByType({ state }) {
      return new Promise((resolve, reject) => {
        let types = [
          "licenType",
          "signType",
          "busiType",
          "busiRole",
          "bankType",
          "bank",
          "yopStatus",
          "orderStatus",
          "payType",
          "channel",
          "after_sales_status",
        ];
        // queryDicByType({ type: types.join() }).then(res=>{
        // 		if (res.code == 200) {
        // 				state.commit('SETTING_DICS', res.data)
        // 		}else{
        // 				console.log(res.message)
        // 		}
        // })
      });
    },
  },
  modules,
});

import axios from "axios";
axios.defaults.withCredentials = false;
import $GLOBAL from "./Global";
import Vue from "vue";
import router from "@/routers/router";
import { Toast } from "vant";
import store from "../../store";
import guestXHR from "./guestXHRList";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

let checkAuth = false; // 接口501 502 授权 或 刷新auth

Vue.use(Toast);

import md5 from "js-md5";
const instance = axios.create({
  baseURL: $GLOBAL.serverSrc, // $GLOBAL.serverSrc,
  timeout: 15000,
  headers: {
    "Content-Type": " application/json",
    Accept: "application/json",
    "Client-Version": "web-0.0.1",
    uuid: "web",
    uuidKey: "201804082117257059",
    "x-api-v": "1.0.2",
  },
});

let urlList = []; //防重复提交
const showLoading = () => {
  Toast.loading({
    message: "加载中...",
    forbidClick: true,
    loadingType: "spinner",
    duration: 15000,
    className: "custom_loading",
  });
};

const hideLoading = () => {
  Toast.clear();
};
let needLoadingRequestCount = 0;
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    showLoading();
  }
  needLoadingRequestCount++;
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    hideLoading();
  }
}
instance.interceptors.request.use(
  (config) => {
    var userToken = localStorage.getItem("userToken");
    // 无token 拦截游客页面需鉴权的请求
    if (
      router.currentRoute.meta.guest &&
      guestXHR.includes(config.url) &&
      !userToken
    ) {
      config.cancelToken = source.token;
      source.cancel();
      return Promise.reject({
        code: "guest",
        message: "游客模式无权限访问" + config.url,
      });
    }
    config.headers["Source"] = 0; // H5传 0
    if (userToken) {
      config.headers["Authorization"] = userToken;
    }
    if (store.state.huid) {
      config.headers["HuId"] = store.state.huid;
    }
    if (urlList.indexOf(config.url) == -1) {
      urlList.push(config.url);
      setTimeout(function() {
        if (urlList.indexOf(config.url) != -1) {
          urlList.splice(urlList.indexOf(config.url), 1);
        }
      }, 5000);
    } else {
      return config;
    }
    // config.headers['Authorization'] = '197C148EF9249EE7BC0401BD6E6C1D65';
    if (!config?.config?.noLoading) showFullScreenLoading();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 拦截响应response，并做一些错误处理
 */
instance.interceptors.response.use(
  (response) => {
    urlList.splice(urlList.indexOf(response.config.url), 1);
    tryHideFullScreenLoading();
    const data = response.data;
    if (data) {
      if (data.code != 200) {
        if (data.code == "-501" || data.code == "-502" || data.code == "-510") {
          // getUserIsLogin用户校验登录态是否有效 不进行自动授权
          if (response.config.url == "/user/userInfo/getUserIsLogin") {
            response.data.code = "-1001";
            return response;
          }
          /**
           * token失效
           * 进入游客模式
           */
          // 授权前执行操作(缓存数据)
          if (
            store.state.beforeAuth &&
            typeof store.state.beforeAuth == "function"
          )
            store.state.beforeAuth();
          if (!checkAuth) {
            checkAuth = true;
            // 无效的token 清除
            store.commit("SET_TOKEN", "");
            store.commit("SET_HUID", "");
            // v1.28.2由于进入路由在router做了base授权校验，能够触发接口请求走到这里的情况：1、base授权后仍需要userinfo主动授权的；2、userinfo主动授权后登录态失效的
            if (store.state.authScope == store.state.snsapi_base) {
              // 不是游客模式页面 跳到游客模式
              // router.replace({
              //   path: '/home'
              // }).then(() => {
              //   checkAuth = null
              // })

              // store.commit('WX_AUTH');

              // 当前是snsapi_base授权 跳到登录页面 走snsapi_userinfo授权
              router
                .replace({
                  path: "/login",
                  query: {
                    link: location.href,
                  },
                })
                .then(() => {
                  checkAuth = null;
                });
            } else if (store.state.authScope == store.state.snsapi_userinfo) {
              // 当前不是snsapi_base授权 刷新页面走snsapi_base授权
              // location.reload();
              store.commit("WX_AUTH");
            }
          }
        } else if (data.code == "-403") {
          /**禁止用户登录访问  */
          // window.location.href = window.location.origin + '/redirect'
        } else if (data.code == "-310") {
          if (!checkAuth) {
            router
              .replace({
                path: "/login",
                query: {
                  link: location.href,
                },
              })
              .then(() => {
                checkAuth = null;
              });
          }
        }
      }
      return response;
    } else {
      tryHideFullScreenLoading();
      const err = new Error("未知错误，请重试");
      err.data = data;
      err.response = response;
      throw err;
    }
    // 根据返回的code值来做不同的处理（和后端约定）
  },
  function(err) {
    var originalRequest = err.config;
    if (
      err.code == "ECONNABORTED" &&
      err.message.indexOf("timeout") != -1 &&
      !originalRequest._retry
    ) {
      // MessageBox.alert('请求超时，请刷新重试').then(()=>{
      //   location.reload();
      // });
      // originalRequest._retry = true
      // return axios.request(originalRequest);
    }

    if (err.code == "guest") {
      // 游客模式错误不抛出
      console.log(err.message);
      return Promise.resolve({ data: err });
    }

    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "请求参数错误";
          break;

        case 401:
          err.message = "未授权，请登录";
          break;

        case 403:
          err.message = "跨域拒绝访问";
          break;

        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`;
          break;

        case 408:
          // MessageBox.alert('请求超时，请刷新重试').then(()=>{
          //   location.reload();
          // });
          err.message = "请求超时";
          break;

        case 500:
          err.message = "服务器内部错误";
          break;

        case 501:
          err.message = "服务未实现";
          break;

        case 502:
          err.message = "网关错误";
          break;

        case 503:
          err.message = "服务不可用";
          break;

        case 504:
          err.message = "网关超时";
          break;

        case 505:
          err.message = "HTTP版本不受支持";
          break;

        default:
      }
    }

    tryHideFullScreenLoading();
    Toast.fail(err.response?.data?.message || err.message);

    return new Promise(() => {});
  }
);

export default instance;

/**
 * get请求
 * @param urlLink
 * @param param
 * @returns {Promise<AxiosResponse>}
 */
export function getData(urlLink, param) {
  if (urlLink.indexOf("?") > -1) {
    urlLink += "&t=" + Date.now();
  } else {
    urlLink += "?t=" + Date.now();
  }
  const url = urlLink;
  const data = Object.assign(param);
  data.userId = store.state.userInfo.puid;
  var sign = genderSign(data);
  data.sign = sign;

  return instance
    .get(url, {
      params: data,
      timeout: 15000,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch(function(error) {
      return Promise.resolve(error.data);
    });
}

/**
 * post请求
 * @param urlLink
 * @param param
 * @returns {Promise<AxiosResponse>}
 */
export function postData(urlLink, param) {
  if (urlLink.indexOf("?") > -1) {
    urlLink += "&t=" + Date.now();
  } else {
    urlLink += "?v=" + Date.now();
  }
  const data = Object.assign(param);
  data.userId = store.state.userInfo.puid;
  var sign = genderSign(data);
  data.sign = sign;
  return instance
    .post(urlLink, data)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch(function(error) {
      return Promise.resolve(error);
    });
}

/**
 * 生成签名
 */
export function genderSign(obj) {
  if (obj != null && obj) {
    var jsonArr = new Array();
    for (var key in obj) {
      //遍历json对象的每个key/value对,p为key
      jsonArr.push(key);
    }
    jsonArr = jsonArr.sort();
    var sign = "";
    for (var i = 0; i < jsonArr.length; i++) {
      var value = obj[jsonArr[i]];
      if (typeof value == "undefined") {
        continue;
      }
      if (sign != "") {
        sign += "&" + jsonArr[i] + "=" + value;
      } else {
        sign += jsonArr[i] + "=" + value;
      }
    }

    sign = md5(sign);
    return sign;
  }
}

export function statistics(obj) {
  // 点击数据统计
  // postData("/stc/into_stc",obj).then((res)=>{
  // })
}

let user = [
  {
    path: "/user/switchAccount",
    name: "switchAccount",
    component: () => import("@/views/user/switchAccount.vue"),
    meta: {
      title: "账号登录",
      noback: true,
    },
  },
];

export default user;
